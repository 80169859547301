section[data-v-286974a3] {
  background: rgba(0, 0, 0, 0.5);
}
section.noDim[data-v-286974a3] {
  background: none;
}
section .confirm__wrapper[data-v-286974a3] {
  max-width: 80vw;
  min-width: 40vw;
  box-shadow: 0 0.266vw 0.533vw -1px rgba(0, 0, 0, 0.2), 0 0.533vw 0.666vw rgba(0, 0, 0, 0.14), 0 1px 1.333vw rgba(0, 0, 0, 0.12);
  width: 89.333vw;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
section .confirm__content[data-v-286974a3] {
  padding-top: 2.667vw;
  line-height: 5.066vw;
  font-size: 4vw;
}
section footer[data-v-286974a3] {
  margin-top: 10.667vw;
  text-align: center;
  font-size: 0;
}
section footer button[data-v-286974a3] {
  width: 50%;
  height: 16vw;
  padding: 0;
  line-height: 5.066vw;
  font-size: 4vw;
  color: #fff;
  background-color: #000;
  border-top: 0.133vw solid #000;
  box-sizing: inherit;
}
section footer button[data-v-286974a3]:first-child {
  color: #000;
  background-color: #fff;
}
