.fb__mypage-top {
  background: #f7f7f7;
  border-top: 3px solid #000;
}
.fb__mypage-top__skeleton {
  background: #fff;
}
.fb__mypage-top .guide {
  display: flex;
  align-items: center;
  height: 140px;
  padding: 0 40px 0 30px;
}
.fb__mypage-top .guide__membership {
  font-size: 22px;
  font-weight: 700;
  flex-basis: 485px;
}
.fb__mypage-top .guide__membership span {
  display: block;
}
.fb__mypage-top .guide__membership span.fb__skeleton {
  height: 20px;
  background: #fff;
}
.fb__mypage-top .guide__membership span.fb__skeleton:nth-of-type(1) {
  width: 205px;
}
.fb__mypage-top .guide__membership span.fb__skeleton:nth-of-type(2) {
  width: 150px;
}
.fb__mypage-top .guide__level {
  font-family: 'Archivo Black';
  font-weight: 300;
}
.fb__mypage-top .guide__my-info {
  display: flex;
  flex-basis: 465px;
  text-align: center;
}
.fb__mypage-top .guide__my-info__order-count, .fb__mypage-top .guide__my-info__mileage, .fb__mypage-top .guide__my-info__available-coupon {
  display: block;
  font-size: 34px;
  font-weight: 700;
}
.fb__mypage-top .guide__my-info__order-count + span, .fb__mypage-top .guide__my-info__mileage + span, .fb__mypage-top .guide__my-info__available-coupon + span {
  display: block;
  font-size: 14px;
}
.fb__mypage-top .guide__my-info__order-count + span:nth-of-type(n + 2), .fb__mypage-top .guide__my-info__mileage + span:nth-of-type(n + 2), .fb__mypage-top .guide__my-info__available-coupon + span:nth-of-type(n + 2) {
  margin-top: 12px;
}
.fb__mypage-top .guide__my-info div {
  margin-left: 85px;
}
.fb__mypage-top .guide__my-info div .fb__skeleton {
  width: 50px;
  height: 35px;
  background: #fff;
}
.fb__mypage-top .guide__my-info div .fb__skeleton:nth-of-type(n + 2) {
  height: 15px;
}
.fb__mypage-top .guide__my-info div:first-of-type {
  margin-left: 0;
}
