.fb__period .datetime-reset-button[data-v-48235787] {
  display: none;
}
.fb__period .period__title[data-v-48235787] {
  font-size: 20px;
  font-weight: bold;
}
.fb__period .period__container[data-v-48235787] {
  margin-top: 20px;
  border: 1px solid #d2d2d2;
}
.fb__period .period__main[data-v-48235787] {
  display: flex;
  align-items: center;
  padding: 30px 0;
}
.fb__period .period__quick[data-v-48235787] {
  display: flex;
  align-items: center;
  padding: 0 45px;
}
.fb__period .period__quick label[data-v-48235787] {
  flex: 0 0 auto;
  display: block;
  font-size: 15px;
}
.fb__period .period__quick label input[data-v-48235787] {
  display: none;
}
.fb__period .period__quick label input:checked + span[data-v-48235787] {
  font-weight: bold;
  color: #000;
}
.fb__period .period__quick label span[data-v-48235787] {
  color: #787878;
  cursor: pointer;
}
.fb__period .period__quick label[data-v-48235787]:after {
  display: inline-block;
  width: 0;
  height: 13px;
  margin: 0 15px;
  border-left: 1px solid #d7d7d7;
  vertical-align: middle;
  content: "";
}
.fb__period .period__quick label[data-v-48235787]:last-of-type:after {
  display: none;
}
.fb__period .period__calendars[data-v-48235787] {
  display: flex;
  align-items: center;
  padding: 0 7px;
}
.fb__period .period__calendars input[type="text"][data-v-48235787] {
  width: 150px;
  height: 40px;
  padding: 0 35px 0 15px;
  background: url("/pc/assets/images/common/btn-calendar.png") no-repeat 116px 10px;
  color: #787878;
  font-size: 16px;
}
.fb__period .period__calendars .fb__tel__separator[data-v-48235787] {
  margin: 0 8px;
  flex: none;
}
.fb__period .period__calendars .calendar__text[data-v-48235787]:focus {
  border: 1px solid #d2d2d2;
}
.fb__period .period__action[data-v-48235787] {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
}
.fb__period .period__action button[data-v-48235787] {
  width: 88px;
  height: 42px;
  margin-right: 5px;
  border: 1px solid #000;
  font-size: 14px;
  font-weight: 600;
}
.fb__period .period__action button[data-v-48235787]:last-of-type {
  margin-right: 0;
}
.fb__period .period__action__reset[data-v-48235787] {
  background: #000;
  color: #fff;
}
