.fb__modal__mask[data-v-cf2cd49c] {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
.fb__modal__mask.categoryDown[data-v-cf2cd49c] {
  top: auto;
  bottom: 0;
  height: calc(100% - 14.5vw);
  background: rgba(0, 0, 0, 0.5);
}
.fb__modal__wrapper[data-v-cf2cd49c] {
  position: relative;
  max-width: 100%;
  min-width: 100px;
  max-height: 100%;
  min-height: 100px;
  overflow-y: auto;
  margin: auto;
}
.fb__modal__close[data-v-cf2cd49c] {
  position: absolute;
  top: 4vw;
  right: 4vw;
  z-index: 5;
  width: 4.8vw;
  height: 4.66667vw;
  background: url(/mobile/assets/images/common/btn-modal-close.png) no-repeat 0 0;
  background-size: 4.8vw 4.66667vw;
  font-size: 0;
  cursor: pointer;
}
