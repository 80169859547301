.fb__modal__mask[data-v-265893c5] {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.fb__modal__wrapper[data-v-265893c5] {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 14.4vw;
  background: #fff;
  box-sizing: border-box;
}
.fb__modal__top[data-v-265893c5] {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 6.66667vw 5.33333vw 3.06667vw;
}
.fb__modal__title[data-v-265893c5] {
  color: #000;
  font-family: "NotoSansCJKkr", serif;
  font-weight: bold;
  font-size: 4.533333vw;
  line-height: 4.66667vw;
}
.fb__modal__close[data-v-265893c5] {
  position: absolute;
  top: 6.66667vw;
  right: 5.33333vw;
  z-index: 5;
  width: 4.8vw;
  height: 4.66667vw;
  background: url(/mobile/assets/images/common/btn-modal-close.png) no-repeat 0 0;
  background-size: 4.8vw 4.66667vw;
  font-size: 0;
  cursor: pointer;
}
