.pagination__wrapper .pagination__btn__left[data-v-35ca9339] {
  margin-right: 7px;
  background: url(/pc/assets/images/common/icon-page-arrow.png) no-repeat 0 0;
}
.pagination__wrapper .pagination__btn__right[data-v-35ca9339] {
  margin-left: 7px;
  background: url(/pc/assets/images/common/icon-page-arrow.png) no-repeat right 0;
}
.pagination__wrapper .pagination button[data-v-35ca9339] {
  position: relative;
  top: 2px;
  width: 6px;
  height: 12px;
  text-indent: -9999px;
}
.pagination__wrapper .pagination__inner[data-v-35ca9339] {
  display: inline-block;
  position: relative;
  width: 64px;
}
.pagination__wrapper .pagination__inner span[data-v-35ca9339] {
  display: block;
  float: left;
  width: 50%;
  text-align: center;
}
.pagination__wrapper .pagination__inner[data-v-35ca9339]:after {
  display: block;
  clear: both;
  content: "";
}
.pagination__wrapper .pagination__inner[data-v-35ca9339]:before {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 11px;
  margin-top: -4px;
  background: #797979;
  content: "";
}
.pagination__wrapper .pagination__now[data-v-35ca9339] {
  color: #000;
}
.pagination__wrapper .pagination__max[data-v-35ca9339] {
  color: #777777;
}
.pagination__wrapper .pagination__list span .test[data-v-35ca9339] {
  color: #ff3e2f;
}
