.fb__mypage__left__info[data-v-d20eaf9e] {
  height: 205px;
  padding-top: 38px;
  color: #000;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.5;
  box-sizing: border-box;
}
.fb__mypage .mypage__left__menu[data-v-d20eaf9e] {
  padding: 20px 0 40px;
  border-top: 1px solid #e5e5e5;
}
.fb__mypage .mypage__left__menu a[data-v-d20eaf9e] {
  display: block;
  margin-top: 10px;
  color: #787878;
  font-size: 14px;
}
.fb__mypage .mypage__left--active[data-v-d20eaf9e] {
  color: #ff3e2f;
}
.fb__mypage .mypage__left__subtitle[data-v-d20eaf9e] {
  margin-bottom: 16px;
  color: #000;
  font-size: 17px;
  font-weight: bold;
}
