.type-comments__total[data-v-1a43d70f] {
  display: block;
  margin-bottom: 2.66666vw;
  text-align: left;
  font-size: 3.46666vw;
  color: #787878;
  letter-spacing: -0.01rem;
}
.type-comments__total em[data-v-1a43d70f] {
  color: #ff3e2f;
}
.type-comments .comment__write__input[data-v-1a43d70f] {
  position: relative;
  margin-bottom: 5.33333vw;
  padding-bottom: 10.4vw;
  border: 0.26666vw solid #e5e5e5;
}
.type-comments .comment__write__textarea[data-v-1a43d70f] {
  height: 23.06666vw;
}
.type-comments .comment__write__textarea textarea[data-v-1a43d70f] {
  width: 100%;
  height: 100%;
  padding: 4vw 3.33333vw;
  line-height: 5.6vw;
  font-size: 3.73333vw;
  color: #000;
  box-sizing: border-box;
}
.type-comments .comment__write__textarea textarea[data-v-1a43d70f]::-webkit-input-placeholder {
  color: #b2b2b2;
}
.type-comments .comment__write__textarea textarea[data-v-1a43d70f]:-ms-input-placeholder {
  color: #b2b2b2;
}
.type-comments .comment__write__textarea textarea[data-v-1a43d70f]::-ms-input-placeholder {
  color: #b2b2b2;
}
.type-comments .comment__write__textarea textarea[data-v-1a43d70f]::placeholder {
  color: #b2b2b2;
}
.type-comments .comment__write__length[data-v-1a43d70f] {
  position: absolute;
  bottom: 3.06666vw;
  left: 3.33333vw;
  color: #787878;
  font-family: 'GillSansWGL', sans-serif;
  font-size: 3.46666vw;
}
.type-comments .comment__write__upload--wrapper[data-v-1a43d70f] {
  position: absolute;
  bottom: -0.26666vw;
  right: 17.86666vw;
  width: 15.73333vw;
  height: 10.66666vw;
  font-size: 0;
}
.type-comments .comment__write__upload--alert[data-v-1a43d70f] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
}
.type-comments .comment__write__upload--photo[data-v-1a43d70f] {
  display: block;
  width: 15.73333vw;
  height: 10.66666vw;
  background: transparent url(/mobile/assets/images/common/ico_comm_photo.png) no-repeat 50% 50%/5.06666vw 4.53333vw;
  font-size: 0;
}
.type-comments .comment__write__upload--commit[data-v-1a43d70f] {
  position: absolute;
  bottom: -0.26666vw;
  right: -0.26666vw;
  width: 18.13333vw;
  height: 10.66666vw;
  background: #000;
  border: 0.26666vw solid #000;
  color: #fff;
  font-size: 3.73333vw;
  box-sizing: border-box;
}
.type-comments .comment__write__upload--del[data-v-1a43d70f] {
  position: absolute;
  top: 0;
  right: 0;
  width: 5.33333vw;
  height: 5.33333vw;
  background: rgba(0, 0, 0, 0.8) url(/mobile/assets/images/common/ico_comm_close-white.png) no-repeat 50% 50%;
  font-size: 0;
}
.type-comments .comment__write__imgWrap[data-v-1a43d70f] {
  display: block;
  padding-bottom: 5.33333vw;
  border-bottom: 0.26666vw solid #e5e5e5;
}
.type-comments .comment__write__imgWrap[data-v-1a43d70f]::after {
  display: block;
  clear: both;
  content: "";
}
.type-comments .comment__write__imgWrap[data-v-1a43d70f]:empty {
  display: none;
}
.type-comments .comment__write__eachImg[data-v-1a43d70f] {
  overflow: hidden;
  position: relative;
  float: left;
  width: 24vw;
  height: 24vw;
  margin-right: 2.66666vw;
  box-sizing: border-box;
  background: #f7f7f7;
}
.type-comments .comment__write__eachImg img[data-v-1a43d70f] {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  max-height: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.type-comments .comment__list[data-v-1a43d70f] {
  margin-top: 16vw;
}
.type-comments .comment__fold[data-v-1a43d70f] {
  padding: 4vw 0 6vw;
  border-bottom: 0.26666vw solid #e5e5e5;
}
.type-comments .comment__open[data-v-1a43d70f] {
  padding: 4.66666vw 2.66666vw;
  background: #f7f7f7;
  border-bottom: 0.26666vw solid #e5e5e5;
}
.type-comments .comment__open.nologin[data-v-1a43d70f] {
  padding: 0;
  border-bottom: 0;
}
.type-comments .comment__open.nologin ul[data-v-1a43d70f] {
  padding: 4.66666vw 2.66666vw;
}
.type-comments .comment__open *[data-v-1a43d70f] {
  color: #787878 !important;
}
.type-comments .comment__open ul[data-v-1a43d70f] {
  padding: 0 2.66666vw;
}
.type-comments .comment__open ul li[data-v-1a43d70f] {
  margin-bottom: 6.66666vw;
}
.type-comments .comment__open ul li[data-v-1a43d70f]:last-of-type {
  margin-bottom: 0;
}
.type-comments .comment__open ul + .comment__write__input[data-v-1a43d70f] {
  margin-top: 6.66666vw;
}
.type-comments .comment__open .comment__write__input[data-v-1a43d70f] {
  margin-bottom: 0;
  background: #FFF;
}
.type-comments .comment__open .comment__write__textarea[data-v-1a43d70f] {
  color: #000 !important;
}
.type-comments .comment__open .comment__write__textarea[data-v-1a43d70f]::-webkit-input-placeholder {
  color: #b2b2b2 !important;
}
.type-comments .comment__open .comment__write__textarea[data-v-1a43d70f]:-ms-input-placeholder {
  color: #b2b2b2 !important;
}
.type-comments .comment__open .comment__write__textarea[data-v-1a43d70f]::-ms-input-placeholder {
  color: #b2b2b2 !important;
}
.type-comments .comment__open .comment__write__textarea[data-v-1a43d70f]::placeholder {
  color: #b2b2b2 !important;
}
.type-comments .comment__open .comment__write__upload--commit[data-v-1a43d70f] {
  color: #fff !important;
}
.type-comments .comment__info[data-v-1a43d70f] {
  position: relative;
  font-size: 0;
}
.type-comments .comment__badge[data-v-1a43d70f] {
  display: inline-block;
  height: 4.26666vw;
  padding: 0 0.13333;
  border: 0.26666vw solid #787878;
  vertical-align: middle;
  font-size: 3.46666vw;
  font-weight: bold;
  font-family: "Archivo Black";
  color: #787878;
  line-height: 3.73333vw;
  box-sizing: border-box;
}
.type-comments .comment__id[data-v-1a43d70f] {
  display: inline-block;
  position: relative;
  margin-left: 1.33333vw;
  padding-right: 3.06666vw;
  vertical-align: middle;
  color: #000;
  min-height: 3.066667vw;
  font-size: 3.73333vw;
  font-family: "Archivo Black";
}
.type-comments .comment__id[data-v-1a43d70f]:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0.26666vw;
  height: 4.26666vw;
  background: #e5e5e5;
}
.type-comments .comment__date[data-v-1a43d70f] {
  display: inline-block;
  margin-left: 2.66666vw;
  vertical-align: middle;
  font-family: 'GillSansWGL', sans-serif;
  font-size: 3.46666vw;
  color: #787878;
}
.type-comments .comment__btns[data-v-1a43d70f] {
  position: absolute;
  top: 0;
  right: 0;
}
.type-comments .comment__btns[data-v-1a43d70f]::after {
  display: block;
  clear: both;
  content: "";
}
.type-comments .comment__btns button[data-v-1a43d70f] {
  display: inline-block;
  vertical-align: middle;
}
.type-comments .comment__btns .comment__btns--re[data-v-1a43d70f] {
  display: inline-block;
  vertical-align: middle;
}
.type-comments .comment__btns--re[data-v-1a43d70f]::after {
  display: block;
  clear: both;
  content: "";
}
.type-comments .comment__btns--re span[data-v-1a43d70f] {
  float: left;
  width: 4.53333vw;
  height: 4.4vw;
  background: url(/mobile/assets/images/common/ico_comm_re.png) no-repeat 0 0/9.33333vw 4.4vw;
  vertical-align: middle;
  font-size: 0;
}
.type-comments .comment__btns--re em[data-v-1a43d70f] {
  float: left;
  margin-left: 2vw;
  font-family: 'GillSansWGL', sans-serif;
  font-size: 4vw;
  color: #787878;
  line-height: 4.26666vw;
}
.type-comments .comment__btns--re.active span[data-v-1a43d70f] {
  background-position: -4.8vw 0;
}
.type-comments .comment__btns--re.active em[data-v-1a43d70f] {
  font-weight: bold;
  color: #000;
}
.type-comments .comment__btns--del[data-v-1a43d70f] {
  width: 3.6vw;
  height: 3.46666vw;
  margin-left: 4vw;
  background: url(/mobile/assets/images/common/ico_comm_re-close.png) no-repeat 0 0/3.6vw 3.46666vw;
  font-size: 0;
}
.type-comments .comment__cont[data-v-1a43d70f] {
  position: relative;
  margin-top: 2vw;
}
.type-comments .comment__cont .comment__write__imgWrap[data-v-1a43d70f] {
  margin-top: 4vw;
  padding-bottom: 0;
  border-bottom: 0;
}
.type-comments .comment__text[data-v-1a43d70f] {
  display: block;
  font-size: 4vw;
  line-height: 6.13333vw;
  letter-spacing: -0.01rem;
  word-break: break-all;
}
