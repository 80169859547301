.fb__select-box[data-v-26690f71] {
  display: block;
  position: fixed;
  z-index: 6;
  right: 0;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 50%;
  background: #fff;
  border-radius: 4vw 4vw 0 0;
  transition: bottom ease .5s;
}
.fb__select-box--open[data-v-26690f71] {
  bottom: 0;
}
.fb__select-box--open + .fb__select-box__mask[data-v-26690f71] {
  display: block;
}
.fb__select-box__btn[data-v-26690f71] {
  position: relative;
  height: 7.33333vw;
  padding: 0 9.59999vw 0 3.33333vw;
  border: 0.26667vw solid #d2d2d2;
  color: #000;
  font-size: 4vw;
  line-height: 7.33333vw;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.fb__select-box__btn[data-v-26690f71]::after {
  display: block;
  position: absolute;
  top: 0;
  right: 2.66666vw;
  width: 3.5vw;
  height: 100%;
  background: url(/mobile/assets/images/common/btn-select.png) no-repeat center center;
  background-size: 2.93333vw 1.86667vw;
  content: "";
}
.fb__select-box__mask[data-v-26690f71] {
  display: none;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.fb__select-box__options[data-v-26690f71] {
  padding: 10.66666vw 0 14.66666vw 0;
}
.fb__select-box__down[data-v-26690f71] {
  position: absolute;
  top: -2.4vw;
  left: 50%;
  width: 31.466vw;
  height: 3.4vw;
  margin-left: -15.733vw;
  background: #fff url(/mobile/assets/images/common/ico_select-custom_arrow.png) no-repeat 50% 50%/3.733vw 1.2vw;
  border-radius: 4vw 4vw 0 0;
}
