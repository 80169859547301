.fb__sns[data-v-057177ec] {
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.fb__sns__sec[data-v-057177ec] {
  position: absolute;
  top: 50%;
  left: 50%;
  padding-top: 7.333vw;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}
.fb__sns .sns[data-v-057177ec] {
  width: 76vw;
  font-size: 0;
  text-align: center;
}
.fb__sns .sns__list[data-v-057177ec] {
  display: inline-block;
  width: 16vw;
  height: 16vw;
  margin-left: 4vw;
  vertical-align: top;
  font-size: 0;
}
.fb__sns .sns__list[data-v-057177ec]:first-child {
  margin-left: 0;
}
.fb__sns .sns-btn[data-v-057177ec] {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.fb__sns .sns-btn.kakao[data-v-057177ec] {
  background: #ffe812 url(/mobile/assets/images/common/ico_comm_sns-total.png) no-repeat 0px 0/64vw 16vw;
}
.fb__sns .sns-btn.facebook[data-v-057177ec] {
  background: #2b5ec5 url(/mobile/assets/images/common/ico_comm_sns-total.png) no-repeat -16vw 0/64vw 16vw;
}
.fb__sns .sns-btn.url[data-v-057177ec] {
  background: #fff url(/mobile/assets/images/common/ico_comm_sns-total.png) no-repeat -32vw 0/64vw 16vw;
}
.fb__sns .sns-btn.more[data-v-057177ec] {
  background: #4f4f4f url(/mobile/assets/images/common/ico_comm_sns-total.png) no-repeat -48vw 0/64vw 16vw;
}
.fb__sns .btn__close[data-v-057177ec] {
  position: absolute;
  top: 0;
  right: -6.667vw;
  width: 4.667vw;
  height: 4.667vw;
  background: url(/mobile/assets/images/common/ico_comm_layer-close.png) no-repeat 0 0/100% auto;
}
