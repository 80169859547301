@charset "UTF-8";
.inp__select[data-v-55323c83] {
  position: relative;
  display: block;
  width: 100%;
}
.inp__select__btn[data-v-55323c83] {
  position: relative;
  width: 100%;
  height: 11.733vw;
  padding: 0 9.59999vw 0 3.33333vw;
  border: 1px solid #EBEBEB;
  color: #000;
  font-size: 4vw;
  line-height: 7.33333vw;
  background: #fff url("/mobile/assets/images/common/ico_select.png") no-repeat calc(100% - 5.0667vw) center;
  background-size: 2.93333vw auto;
}
.inp__select__box[data-v-55323c83] {
  position: relative;
}
.inp__select__box .inp__select__btn[data-v-55323c83] {
  text-align: left;
}
.inp__select__box.open .inp__select__btn[data-v-55323c83] {
  border: 1px solid #000;
}
.inp__select__box.open .inp__select__options[data-v-55323c83] {
  display: block;
}
.inp__select__options[data-v-55323c83] {
  display: none;
  position: absolute;
  left: 0;
  top: calc(11.733vw - 1px);
  width: 100%;
  max-height: 40vw;
  padding: 1.06vw 4.8vw 1.06vw 4vw;
  border: 1px solid #000;
  border-top: 1px solid #EBEBEB;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  z-index: 10;
}
.inp__select__options[data-v-55323c83]::-webkit-scrollbar {
  width: 3px;
  /* 스크롤바의 너비 */
  margin-right: 10px;
}
.inp__select__options[data-v-55323c83]::-webkit-scrollbar-thumb {
  height: 30%;
  /* 스크롤바의 길이 */
  background: rgba(0, 0, 0, 0.2);
  /* 스크롤바의 색상 */
  border-radius: 20px;
  visibility: visible;
}
.inp__select__options label[data-v-55323c83] {
  display: block;
  width: 100%;
  padding: 2vw 0;
  text-align: left;
  color: #787878;
  font-size: 3.466vw;
  line-height: 1.5;
}
.inp__select__options input[type=radio]:checked + span[data-v-55323c83] {
  color: #000;
}
.inp__select__options input[type=radio]:disabled + span[data-v-55323c83] {
  color: #ccc;
}
