.fb__toast__mask[data-v-a8403bce] {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
.fb__toast__mask.categoryDown[data-v-a8403bce] {
  top: auto;
  bottom: 0;
  height: calc(100% - 14.5vw);
  background: rgba(0, 0, 0, 0.5);
}
.fb__toast__wrapper[data-v-a8403bce] {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  background-color: #fff;
  border-radius: 30px 30px 0 0;
  box-shadow: -3px 0 8px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  transition: all 0.3s linear;
}
.fb__toast__close[data-v-a8403bce] {
  position: absolute;
  top: 4vw;
  left: 50%;
  z-index: 5;
  width: 8.533333vw;
  height: 0.8vw;
  background-color: #EBEBEB;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 10px;
  cursor: pointer;
  text-indent: -9999em;
  overflow: hidden;
}
