section[data-v-01198c55] {
  background: rgba(0, 0, 0, 0.5);
}
section.noDim[data-v-01198c55] {
  background: none;
}
section .alert__wrapper[data-v-01198c55] {
  max-width: 80vw;
  min-width: 40vw;
  padding: 0;
  box-shadow: 0 0.266vw 0.533vw -1px rgba(0, 0, 0, 0.2), 0 0.533vw 0.666vw rgba(0, 0, 0, 0.14), 0 1px 1.333vw rgba(0, 0, 0, 0.12);
  width: 89.333vw;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
section .alert__content[data-v-01198c55] {
  line-height: 5.066vw;
  font-size: 4vw;
  word-break: keep-all;
  word-wrap: break-word;
  padding: 2.667vw 13vw 0;
}
section footer[data-v-01198c55] {
  margin-top: 10.667vw;
  text-align: center;
  font-size: 0;
  width: 100%;
}
section footer button[data-v-01198c55] {
  width: 100%;
  height: 16vw;
  padding: 0;
  line-height: 5.066vw;
  font-size: 4vw;
  color: #fff;
  background-color: #000;
}
