.fb__modal__mask[data-v-2eaaadde] {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.fb__modal__wrapper[data-v-2eaaadde] {
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 4vw;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-sizing: border-box;
  box-shadow: 0.66667vw 1.06667px 6.66667vw rgba(0, 0, 0, 0.3);
}
.fb__modal__top[data-v-2eaaadde] {
  display: block;
  padding-bottom: 4vw;
  border-bottom: 0.26667vw solid #e5e5e5;
}
.fb__modal__title[data-v-2eaaadde] {
  color: #000;
  font-size: 4.533333vw;
  line-height: 4vw;
}
.fb__modal__close[data-v-2eaaadde] {
  position: absolute;
  top: 4vw;
  right: 4vw;
  z-index: 5;
  width: 4vw;
  height: 4vw;
  background: url(/mobile/assets/images/common/btn-modal-close-small.png) no-repeat 0 0;
  background-size: 4vw 4vw;
  font-size: 0;
  cursor: pointer;
}
